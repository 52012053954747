import axios from "../instance";

export default {
    getAll: () => {
        return axios.api.get("/vans").then((response) => response.data);
    },
    getAvalibleVans: (warehouseId, branchId) => {
        return axios.api.get(`/warehouses/getAvalibleVans?warehouseId=${warehouseId}&branchId=${branchId}`).then((response) => response.data);
    },
    getOne: (id) => {
        return axios.api.get(`/vans/${id}`).then((response) => response.data);
    },
    search: (body) => {
        return axios.api.post("/vans/search", body).then((response) => response.data);
    },
    create: (body) => {
        return axios.api.post("/vans", body).then((response) => response.data);
    },
    update: (id, body) => {
        return axios.api.put(`/vans/${id}`, body).then((response) => response.data);
    },
    delete: (id) => {
        return axios.api.delete(`/vans/${id}`).then((response) => response.data);
    }
};
