import axios from "../instance";

export default {
    getAll: (productGroupId) => {
        return axios.api.get(`/subProductGroups`).then((response) => response.data);
        // return axios.api.get(`/subProductGroups?productGroupId=${productGroupId}`).then((response) => response.data);
    },
    getAllByProductGroupId: (productGroupId) => {
        return axios.api.get(`/subProductGroups?productGroupId=${productGroupId}`).then((response) => response.data);
    },
    getOne: (id) => {
        return axios.api.get(`/subProductGroups/${id}`).then((response) => response.data);
    },
    search: (body) => {
        return axios.api.post("/subProductGroups/search", body).then((response) => response.data);
    },
    create: (body) => {
        return axios.api.post("/subProductGroups", body).then((response) => response.data);
    },
    update: (id, body) => {
        return axios.api.put(`/subProductGroups/${id}`, body).then((response) => response.data);
    },
    delete: (id) => {
        return axios.api.delete(`/subProductGroups/${id}`).then((response) => response.data);
    }
};
