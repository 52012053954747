import axios from "../instance";

export default {
  getAll: (expired_product) => {
    return axios.api
      .get(`/cutOffStock?expired_product=${expired_product}`)
      .then((response) => response.data);
  },
  getAllItem: (warehouseId) => {
    return axios.api
      .get(`/cutOffStock/items?warehouseId=${warehouseId}`)
      .then((response) => response.data);
  },
  getOne: (id) => {
    return axios.api
      .get(`/cutOffStock/${id}`)
      .then((response) => response.data);
  },
  search: (body) => {
    return axios.api
      .post("/cutOffStock/search", body)
      .then((response) => response.data);
  },
  create: (body) => {
    return axios.api
      .post("/cutOffStock", body)
      .then((response) => response.data);
  },
  update: (id, body) => {
    return axios.api
      .put(`/cutOffStock/${id}`, body)
      .then((response) => response.data);
  },
  delete: (id) => {
    return axios.api
      .delete(`/cutOffStock/${id}`)
      .then((response) => response.data);
  },
  deleteItem: (id) => {
    return axios.api
      .delete(`/cutOffStock/item/${id}`)
      .then((response) => response.data);
  },
};
