import axios from "../instance";

export default {
  getAll: () => {
    return axios.api.get("/customer").then((response) => response.data);
  },
  getOne: (id) => {
    return axios.api.get(`/customer/${id}`).then((response) => response.data);
  },
  search: (body, pos) => {
    return axios.api
      .post(`/customer/search?pos=${pos}`, body)
      .then((response) => response.data);
  },
  create: (body) => {
    return axios.api.post("/customer", body).then((response) => response.data);
  },
  update: (id, body) => {
    return axios.api
      .put(`/customer/${id}`, body)
      .then((response) => response.data);
  },
  delete: (id) => {
    return axios.api
      .delete(`/customer/${id}`)
      .then((response) => response.data);
  },
};
