import axios from "../instance";

export default {
    getAll: (warehouseId) => {
        return axios.api.get(`/contacts?warehouseId=${warehouseId}`).then((response) => response.data);
    },
    getOne: (id) => {
        return axios.api.get(`/contacts/${id}`).then((response) => response.data);
    },
    search: (body) => {
        return axios.api.post("/contacts/search", body).then((response) => response.data);
    },
    create: (body) => {
        return axios.api.post("/contacts", body).then((response) => response.data);
    },
    update: (id, body) => {
        return axios.api.put(`/contacts/${id}`, body).then((response) => response.data);
    },
    delete: (id) => {
        return axios.api.delete(`/contacts/${id}`).then((response) => response.data);
    }
};
