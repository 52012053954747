import axios from "../instance";

export default {
  getAll: () => {
    return axios.api.get("/productGroups").then((response) => response.data);
  },
  getOne: (id) => {
    return axios.api
      .get(`/productGroups/${id}`)
      .then((response) => response.data);
  },
  search: (body) => {
    return axios.api
      .post("/productGroups/search", body)
      .then((response) => response.data);
  },
  create: (body) => {
    return axios.api
      .post("/productGroups", body)
      .then((response) => response.data);
  },
  update: (id, body) => {
    return axios.api
      .put(`/productGroups/${id}`, body)
      .then((response) => response.data);
  },
  delete: (id) => {
    return axios.api
      .delete(`/productGroups/${id}`)
      .then((response) => response.data);
  },
};
