import axios from "../instance";

export default {
  getAll: () => {
    return axios.api.get(`/pointOfSale`).then((response) => response.data);
  },
  getOne: (id) => {
    return axios.api
      .get(`/pointOfSale/${id}`)
      .then((response) => response.data);
  },
  search: (body) => {
    return axios.api
      .post(`/pointOfSale/search`, body)
      .then((response) => response.data);
  },
  create: (body) => {
    return axios.api
      .post("/pointOfSale", body)
      .then((response) => response.data);
  },
  update: (id, body) => {
    return axios.api
      .put(`/pointOfSale/${id}`, body)
      .then((response) => response.data);
  },
  delete: (id) => {
    return axios.api
      .delete(`/pointOfSale/${id}`)
      .then((response) => response.data);
  },
};
