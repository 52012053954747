import axios from "../instance";

export default {
    // Get All
    getAll: (status) => {
        if (status) {
            return axios.api.get(`/products?status=${status}`).then((response) => response.data);
        } else {
            return axios.api.get(`/products`).then((response) => response.data);
        }
    },
    getProductPrice: (productId) => {
        return axios.api.get(`/productPrices?productId=${productId}`).then((response) => response.data);
    },
    getAllByWarehouse: (warehouse) => {
        if (warehouse) {
            return axios.api.get(`/products?warehouse=${warehouse}`).then((response) => response.data);
        } else {
            return axios.api.get(`/products`).then((response) => response.data);
        }
    },
    // Get All
    // .post(`/products/search?pos=${pos}&search_by=${search_by}`, body)
    search: (body, pos, search_by) => {
        return axios.api.post(`/products/search`, body).then((response) => response.data);
    },
    getOne: (id) => {
        return axios.api.get(`/products/${id}`).then((response) => response.data);
    },
    create: (body) => {
        return axios.api.post("/products", body).then((response) => response.data);
    },
    update: (id, body) => {
        return axios.api.put(`/products/${id}`, body).then((response) => response.data);
    },
    delete: (id) => {
        return axios.api.delete(`/products/${id}`).then((response) => response.data);
    },

    // exp date
    searchExpByDate: (body) => {
        return axios.api.post(`/products/expire/searchExpire`, body).then((response) => response.data);
    },
    searchByCode: (companyId, products_code) => {
        return axios.api.get(`/products?companyId=${companyId}&products_code=${products_code}`).then((response) => response.data);
    },
    searchByBarcode: (companyId, products_barcode) => {
        return axios.api.get(`/products?companyId=${companyId}&products_barcode=${products_barcode}`).then((response) => response.data);
    },
    getAllExpire: () => {
        return axios.api.get(`/products/expire/list`).then((response) => response.data);
    },
    updateExp: (body) => {
        return axios.api.post(`/products/expire`, body).then((response) => response.data);
    },
    deleteExp: (id) => {
        return axios.api.delete(`/products/expire/${id}`).then((response) => response.data);
    }
};
