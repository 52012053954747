import axios from "../instance";

export default {
    getAll: () => {
        return axios.api.get("/mWarehouseVans").then((response) => response.data);
    },
    getQuery: (warehouseId) => {
        return axios.api.get(`/mWarehouseVans?warehouseId=${warehouseId}`).then((response) => response.data);
    },
    getOne: (id) => {
        return axios.api.get(`/mWarehouseVans/${id}`).then((response) => response.data);
    },
    search: (body) => {
        return axios.api.post(`/mWarehouseVans/search`, body).then((response) => response.data);
    },
    create: (body) => {
        return axios.api.post("/mWarehouseVans", body).then((response) => response.data);
    },
    update: (id, body) => {
        return axios.api.put(`/mWarehouseVans/${id}`, body).then((response) => response.data);
    },
    delete: (id) => {
        return axios.api.delete(`/mWarehouseVans/${id}`).then((response) => response.data);
    }
};
