import warehouse from "./warehouse";
import mWarehouseVans from "./mWarehouseVans";
import mainProduct from "./mainProduct";
import subProduct from "./subProduct";
import typeProduct from "./typeProduct";
import product from "./product";
import branch from "./branch";
import contact from "./contact";
import van from "./van";
import unit from "./unit";
import cutOffStock from "./cutOffStock";

export default {
    warehouse,
    mWarehouseVans,
    mainProduct,
    subProduct,
    typeProduct,
    product,
    branch,
    contact,
    van,
    unit,
    cutOffStock
};
