import axios from "../instance";

export default {
  getAll: (sales_type) => {
    return axios.api
      .get(`/salesOrder?sales_type=${sales_type}`)
      .then((response) => response.data);
  },
  getOne: (id) => {
    return axios.api.get(`/salesOrder/${id}`).then((response) => response.data);
  },
  search: (body, sales_type) => {
    return axios.api
      .post(`/salesOrder/search?sales_type=${sales_type}`, body)
      .then((response) => response.data);
  },
  create: (body) => {
    return axios.api
      .post("/salesOrder", body)
      .then((response) => response.data);
  },
  update: (id, body) => {
    return axios.api
      .put(`/salesOrder/${id}`, body)
      .then((response) => response.data);
  },
  delete: (id) => {
    return axios.api
      .delete(`/salesOrder/${id}`)
      .then((response) => response.data);
  },
};
